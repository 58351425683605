import React from 'react'
import PropTypes from 'prop-types'
import { Container, Col, Row } from 'reactstrap'
import ImageFluid from './imageFluid'

const Instructeurs = ({ image, altText, children }) => (
  <Container fluid style={{ marginBottom: '2rem', display: 'inline-block' }}>
    <Row className="align-items-center">
      <Col sm>
        <ImageFluid
          src={image}
          className="img-fluid justify-content-between"
          alt={altText}
          style={{ width: '100%', height: 'auto', marginBottom: '1 rem' }}
        />
      </Col>
      <Col sm>{children}</Col>
    </Row>
    <hr />
  </Container>
)

Instructeurs.propTypes = {
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
  children: PropTypes.node.isRequired
}

Instructeurs.defaultProps = {
  altText: 'Instructeur'
}

export default Instructeurs
