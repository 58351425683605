import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageFluid = ({ src, altText }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === src
      )
      if (!image) {
        return null
      }
      return (
        <Img
          fluid={image.node.fluid}
          className="img-fluid"
          alt={altText}
          style={{
            maxWidth: '550px',
            width: '100%',
            display: 'block',
            margin: '2rem auto 3rem auto',
          }}
        />
      )
    }}
  />
)

ImageFluid.defaultProps = {
  altText: 'Aucune description disponible'
}

ImageFluid.propTypes = {
  src: PropTypes.string.isRequired,
  altText: PropTypes.string,
}
export default ImageFluid
